import React from "react";
import { clsx } from "clsx";

/**
 * The properties for {@link Pane}
 */
export type PaneProps = {
    /** the children of the pane */
    children?: React.ReactNode;

    /** Additional classnames */
    className?: string;
};

/**
 * The default pane
 */
export default function Pane(props: PaneProps) {
    return (
        <div
            className={clsx(
                // Layout
                "flex rounded-lg border p-12 shadow-xl",
                // Colors
                "border-zinc-200 bg-zinc-100/50 dark:border-zinc-800 dark:bg-zinc-800/10",
                // Effects
                "backdrop-blur-sm",
                props.className,
            )}
        >
            {props.children}
        </div>
    );
}
