import React from "react";

/**
 * The properties for {@link Heading}
 */
export type HeadingProps = {
    /** The children of the heading */
    children?: React.ReactNode;
};

/**
 * A heading
 */
export function Heading(props: HeadingProps) {
    return <h1 className={"font-pixelify text-3xl dark:text-zinc-100"}>{props.children}</h1>;
}

/**
 * A heading
 */
export function Subheading(props: HeadingProps) {
    return <h2 className={"font-pixelify text-2xl dark:text-zinc-100"}>{props.children}</h2>;
}
