import { Outlet, createRootRoute } from "@tanstack/react-router";
import React from "react";
import Background from "../components/background";

export const Route = createRootRoute({
    component: () => (
        <>
            <Background />
            <Outlet />
        </>
    ),
});
