import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import StepBar from "../components/base/step-bar";
import Pane from "../components/base/pane";
import { Subheading, Heading } from "../components/base/heading";

const day1 = [
    {
        label: "Start",
        start: new Date("2024-07-18 10:00"),
        end: new Date("2024-07-18 10:00"),
        description: "",
    },
    {
        label: "Produktportfolio-Strategie Workshop - Jonas",
        start: new Date("2024-07-18 11:00"),
        end: new Date("2024-07-18 13:00"),
        description: "Teilnehmer: Chris, Aaron, Daniel, Philipp, Fynn, Jonas",
    },
    {
        label: "Red-Teaming - Olli",
        start: new Date("2024-07-18 11:00"),
        end: new Date("2024-07-18 13:00"),
        description: "",
    },
    {
        label: "Foto Session 1",
        start: new Date("2024-07-18 12:00"),
        end: new Date("2024-07-18 13:00"),
        description: "Bilder für Website und Truffleport",
    },
    {
        label: "3D Gaussian Splatting - Milena",
        start: new Date("2024-07-18 14:00"),
        end: new Date("2024-07-18 15:00"),
        description: "3D Gaussian Splatting for Real-Time Radiance Field Rendering",
    },
    {
        label: "Trufflepig Vision - Aaron",
        start: new Date("2024-07-18 16:05"),
        end: new Date("2024-07-18 16:20"),
        description: "",
    },
    {
        label: "Sales Workshop",
        start: new Date("2024-07-18 16:25"),
        end: new Date("2024-07-18 17:25"),
        description: "",
    },
    {
        label: "War Stories Workshop",
        start: new Date("2024-07-18 17:30"),
        end: new Date("2024-07-18 18:30"),
        description: "",
    },
    {
        label: "Foto Session 2",
        start: new Date("2024-07-18 18:45"),
        end: new Date("2024-07-18 19:45"),
        description: "Bilder für Website und Truffleport",
    },
    {
        label: "Grillen",
        start: new Date("2024-07-18 19:00"),
        end: new Date("2024-07-18 24:00"),
        description: "",
    },
];

const day2 = [
    {
        label: "Start",
        start: new Date("2024-07-19 10:00"),
        end: new Date("2024-07-19 10:00"),
        description: "",
    },
    {
        label: "Power User shortcuts - Jan",
        start: new Date("2024-07-19 10:30"),
        end: new Date("2024-07-19 11:00"),
        description: "",
    },
    {
        label: "Foto Session 3",
        start: new Date("2024-07-19 11:15"),
        end: new Date("2024-07-19 12:15"),
        description: "Bilder für Website und Truffleport",
    },
    {
        label: "Kraken Workflows - Chris / Olli",
        start: new Date("2024-07-19 14:00"),
        end: new Date("2024-07-19 14:30"),
        description: "Diskussionsrunde",
    },
    {
        label: "Command & Control: Exfiltration - Niklas",
        start: new Date("2024-07-19 15:15"),
        end: new Date("2024-07-19 15:45"),
        description: "Diskussionsrunde und Ideenfindung",
    },
    {
        label: "Forensik: Best Practices Workshop - Chris",
        start: new Date("2024-07-19 16:00"),
        end: new Date("2024-07-19 16:30"),
        description: "",
    },
    {
        label: "Ende",
        start: new Date("2024-07-19 17:00"),
        end: new Date("2024-07-19 17:00"),
        description: "",
    },
];

/**
 * The properties for {@link Index}
 */
export type IndexProps = {};

/**
 * The index site
 */
export default function Index(props: IndexProps) {
    return (
        <div className={"my-6 flex w-full items-center justify-center p-2 sm:my-12"}>
            <div className={"flex flex-col gap-6"}>
                <Pane className={"justify-center"}>
                    <Heading>Truffledays - July</Heading>
                </Pane>
                <Pane>
                    <div className={"grid w-full gap-12 sm:grid-cols-2 sm:gap-24"}>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 1</Subheading>
                            <StepBar
                                steps={day1.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 2</Subheading>
                            <StepBar
                                steps={day2.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                    </div>
                </Pane>
            </div>
        </div>
    );
}

export const Route = createFileRoute("/")({
    component: Index,
});
