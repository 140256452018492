import React, { useEffect } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { loadPolygonMaskPlugin } from "@tsparticles/plugin-polygon-mask";
import { IOptions, RecursivePartial } from "@tsparticles/engine";

const options: RecursivePartial<IOptions> = {
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: "bubble",
                parallax: {
                    enable: true,
                    force: 10,
                    smooth: 100,
                },
            },
        },
        modes: {
            bubble: {
                duration: 200,
                size: 6,
                color: "#fff",
            },
        },
    },
    fpsLimit: 120,
    pauseOnBlur: true,
    pauseOnHover: true,
    pauseOnOutsideViewport: true,
    particles: {
        color: { value: "#000" },
        number: { density: { enable: true }, value: 300 },
        shape: { type: "circle", close: true },
        links: {
            color: "#000",
            distance: 150,
            enable: true,
            width: 1,
        },
        move: {
            direction: "none",
            enable: true,
            outModes: { default: "bounce" },
            random: false,
            speed: 2,
            straight: false,
        },
        size: {
            value: {
                min: 1,
                max: 3,
            },
        },
    },
    detectRetina: true,
};

/**
 * The properties for {@link Background}
 */
export type BackgroundProps = {};

/**
 * The background of the application
 */
export default function Background(props: BackgroundProps) {
    const [init, setInit] = React.useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadPolygonMaskPlugin(engine);
            await loadSlim(engine);
        }).then(() => setInit(true));
    }, []);

    return init && <Particles className={"fixed left-0 top-0 -z-10 h-screen w-full"} options={options} />;
}
